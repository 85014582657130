import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, set, useFieldArray, useForm } from 'react-hook-form'
import * as yup from 'yup'
import Box from '../../Global/Box'
import InputController from '../../Global/InputController'
import { useEffect, useState } from 'react'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import { toastSweetAlertNotifications } from '../../Helpers/ToastSweetAlertNotifications'

const ModalQuantityLabels = ({
  labelsInformation,
  setLabelsInformation,
  refModalQuantity,
  closeModal,
  setCloseModal,
  setPrintLabels,
  setPrintingLabels,
  setPrintLabelsWithDifferentPrice,
}) => {
  const [loadingPage, setLoadingPage] = useState(false)
  const [quantityValues, setQuantityValues] = useState([])
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [currentData, setCurrentData] = useState([])
  const [searchValue, setSearchValue] = useState('')

  const validationSchema = yup.object().shape({
    labels: yup.array().of(
      yup.object().shape({
        quantity: yup.number().typeError('La cantidad es requerida'),
      })
    ),
  })

  const {
    methods,
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const { fields, append } = useFieldArray({
    control,
    name: 'labels',
  })

  useEffect(() => {
    if (closeModal) {
      setCurrentPage(1)
    }
  }, [closeModal])

  useEffect(() => {
    if (labelsInformation?.length > 0 && searchValue === '') {
      let list = []
      labelsInformation.map((label, index) => {
        setValue(`labels[${index}].quantity`, parseInt(label.quantity))
        list.push(label.quantity)
      })
      // console.log('labelsInformation', labelsInformation)
      setQuantityValues(list)
      const total = Math.ceil(labelsInformation.length / 10)
      setTotalPages(total)
      const startIndex = (currentPage - 1) * itemsPerPage
      const endIndex = startIndex + itemsPerPage
      setCurrentData(labelsInformation.slice(startIndex, endIndex))
      // if (currentPage === 1) {
      //   document.getElementById('prev-page').disabled = true
      // } else {
      //   document.getElementById('prev-page').disabled = false
      // }
      // if (currentPage === totalPages) {
      //   document.getElementById('next-page').disabled = true
      // } else {
      //   document.getElementById('next-page').disabled = false
      // }
    }
  }, [labelsInformation, currentPage, itemsPerPage, searchValue])

  useEffect(() => {
    if (searchValue !== '' && labelsInformation?.length > 0) {
      // console.log('labelsInformation', labelsInformation)

      const filteredData = labelsInformation.filter(
        (label) =>
          label.SKU.toLowerCase().includes(searchValue.toLowerCase()) ||
          label.Descripcion.toLowerCase().includes(searchValue.toLowerCase())
      )

      // console.log('filteredData', filteredData)
      const total = Math.ceil(filteredData.length / 10)
      setTotalPages(total)
      const startIndex = (currentPage - 1) * itemsPerPage
      const endIndex = startIndex + itemsPerPage
      setCurrentData(filteredData.slice(startIndex, endIndex))

      if (filteredData.length === 0) {
        document.getElementById('prev-page').disabled = true
        toastSweetAlertNotifications({
          mode: 'info',
          message: 'No se encontraron resultados',
        })
      }
    } else {
      const total = Math.ceil(labelsInformation.length / 10)
      setTotalPages(total)
      const startIndex = (currentPage - 1) * itemsPerPage
      const endIndex = startIndex + itemsPerPage
      setCurrentData(labelsInformation.slice(startIndex, endIndex))
    }
  }, [searchValue, labelsInformation, currentPage, itemsPerPage])

  // console.log('errors', errors)

  const handleQuantity = (value, id, idx) => {
    if (value === '') return
    setLoadingPage(true)
    if (value == 0 || value < 0) {
      setLoadingPage(false)
      return toastSweetAlert(
        {
          mode: 'error',
          message: 'La cantidad no puede ser 0 o menor a 0',
        },
        setValue(`labels[${idx}].quantity`, 1)
      )
    }

    const findLabel = labelsInformation.find((label, index) => index === idx)

    // console.log('findLabel', findLabel)

    findLabel.quantity = parseInt(value)
    // console.log('findLabel', findLabel)
    setLoadingPage(false)
    // const newQuantityValues = [...quantityValues]
    // newQuantityValues[idx] = parseInt(value, 10)
    // setQuantityValues(newQuantityValues)
  }

  const handleNextPage = (e) => {
    e.preventDefault()
    if (currentPage === totalPages) return
    setCurrentPage((nextPage) => nextPage + 1)
  }

  const handlePrevPage = (e) => {
    e.preventDefault()
    if (currentPage === 1) return
    setCurrentPage((prevPage) => prevPage - 1)
  }

  const handleSearchLabel = (e) => {
    setCurrentPage(1)
    setSearchValue(e.target.value)
  }

  const onSubmit = (data) => {
    // console.log(data.labels)
    // setPrintLabels(true)
    const updatedLabelsInformation = labelsInformation.map((label, index) => {
      return {
        ...label,
        quantity: isNaN(data.labels[index].quantity)
          ? 1
          : parseInt(data.labels[index].quantity),
      }
    })
    // console.log('labelsInformationONSUBMIT', labelsInformation)
    setLabelsInformation(updatedLabelsInformation)
    setPrintingLabels(true)
    refModalQuantity.current.click()
    document.getElementById('search-labels').value = ''
    setSearchValue('')
    reset({
      labels: [
        {
          quantity: 1,
        },
      ],
    })
  }

  const handleCloseModal = (e) => {
    setCloseModal(true)
    setCurrentPage(1)
    document.getElementById('search-labels').value = ''
    setSearchValue('')
    setPrintLabelsWithDifferentPrice(false)
    reset({
      labels: [
        {
          quantity: 1,
        },
      ],
    })
  }

  return (
    <FormProvider {...methods}>
      <form
        className={`${loadingPage ? 'processing-file' : ''}`}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box
          withCard={false}
          errors={errors}
          btnState={loadingPage}
          btnSubmit
          btnSubmitText="Imprimir etiquetas"
          btnSecondFunction={(e) => handleCloseModal(e)}
          btnSecondFunctionTitle="Cancelar"
          btnSecondFunctionDismissModal={true}
          content={
            <>
              <div className="col-12 col-sm-10 col-md-10 col-lg-10">
                <InputController
                  id="search-labels"
                  name="search-labels"
                  control={control}
                  placeholder="Buscar etiqueta"
                  // label="Buscar etiqueta"
                  debounceTime={700}
                  changeAction={(e) => handleSearchLabel(e)}
                  autocomplete="off"
                  spellcheck="false"
                />
              </div>
              <hr />
              <div>
                <table>
                  <thead>
                    <tr>
                      <th
                        style={{
                          width: '15%',
                        }}
                      >
                        SKU
                      </th>
                      <th
                        style={{
                          width: '80%',
                        }}
                      >
                        Nombre
                      </th>
                      <th
                        style={{
                          width: '5%',
                        }}
                      >
                        Cantidad
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData?.length > 0 &&
                      currentData.map((label, localIndex) => {
                        const globalIdx = parseInt(label.id) - 1
                        // console.log('globalIdx', globalIdx)
                        return (
                          <tr key={globalIdx}>
                            <td>{label.SKU}</td>
                            <td>{label.Descripcion}</td>
                            <td>
                              <InputController
                                id={`quantity_${label.id}`}
                                name={`labels[${globalIdx}].quantity`}
                                // type="number"
                                control={control}
                                changeAction={(e) =>
                                  handleQuantity(
                                    e.target.value,
                                    label.id,
                                    globalIdx
                                  )
                                }
                                defaultValue={label.quantity}
                                // debounceTime={700}
                              />
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
                {/* {Array.from(
                  { length: totalPages },
                  (_, index) => index + 1
                ).map((page) => (
                  <button
                    key={page}
                    onClick={() => handlePageChange(page)}
                    style={{
                      fontWeight: currentPage === page ? 'bold' : 'normal',
                    }}
                  >
                    {page}
                  </button>
                ))} */}
                <hr />
                <div className="pagination-modal-quantity-labels">
                  <button
                    id="prev-page"
                    className="arrow-pagination-modal-quantity-labels"
                    onClick={(e) => handlePrevPage(e)}
                    disabled={currentPage === 1 ? true : false}
                  >
                    {'<'}
                  </button>
                  <span className="current-page-modal-quantity-labels">
                    {`${currentPage} de ${totalPages}`}
                  </span>
                  <button
                    id="next-page"
                    className="arrow-pagination-modal-quantity-labels"
                    onClick={(e) => handleNextPage(e)}
                    disabled={currentPage === totalPages ? true : false}
                  >
                    {'>'}
                  </button>
                </div>
              </div>
            </>
          }
        />
      </form>
    </FormProvider>
  )
}

export default ModalQuantityLabels
