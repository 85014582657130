import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import logoVA from '../../../Assets/Images/labelsVA.png'

const PrintLabels = React.forwardRef(
  ({
    labelsInformation,
    setLabelsInformation,
    componentRef,
    printingLabels,
    setPrintingLabels,
    setIsPrinting,
    tagDiscount,
    tagsForFridge,
    pageBreakQuantity,
  }) => {
    const [renderingCounter, setRenderingCounter] = useState(0)
    const pageBreak = {
      pageBreakAfter: 'always',
      marginTop: '10mm',
    }

    const formatterCurrency = (number) => {
      let numberFormat = new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN',
        maximumFractionDigits: 2,
      }).format(number)

      return numberFormat
    }

    // useEffect(() => {
    //   if (labelsInformation.length > 0 && printingLabels) {
    //     // handleLabel()
    //     setIsPrinting(false)
    //   }

    //   // handleBarcode(labelsInformation)
    // }, [labelsInformation, printingLabels])

    const handlePrintLine = (description) => {
      if (description.length > 25) {
        return {
          fontSize: '12px',
          lineHeight: '12px',
        }
      }
    }

    const handlePageBreaker = (index) => {
      // console.log('index', index)
      if (index > 0 && index % pageBreakQuantity === 0) {
        // console.log('page-breaker SALTO', index)
        return <div className="new-page" key={`page-break-${index}`}></div>
      }
    }
    const handlePageBreakerFridge = (index) => {
      // console.log('index', index)
      if (index > 0 && index % 16 === 0) {
        // console.log('page-breaker SALTO', index)
        return <div className="new-page" key={`page-break-${index}`}></div>
      }
    }

    const handleLabel = () => {
      try {
        let totalLabelsCount = 0
        let totalLabelsRendered = 0
        let currentPage = []
        const pages = []
        // setIsPrinting(false)

        const totalQuantity = labelsInformation.reduce(
          (acc, label) => acc + parseInt(label.quantity),
          0
        )

        // console.log('totalQuantity', totalQuantity)

        for (let i = 0; i < labelsInformation.length; i++) {
          const labelQuantity = parseInt(labelsInformation[i].quantity)
          let totalPages = totalQuantity / pageBreakQuantity
          totalPages = Math.ceil(totalPages)
          // console.log('totalLabelsCount', totalLabelsCount)

          // console.log('totalPages', totalPages)
          for (let j = 0; j < labelQuantity; j++) {
            totalLabelsRendered++
            currentPage.push(
              <>
                <style>
                  {`
                  @font-face{
                    font-family: 'Quicksand';
                    src: url('../../../Assets/Fonts/QuickSand/Quicksand-Medium.ttf);
                    font-weight: normal;
                    font-style: normal;
                    font-display: swap;
                  }

                  .textFont{
                    font-family: 'Quicksand', sans-serif;
                  }

                  `}
                </style>
                <div className="card-label-item" key={`${i}-${j}`}>
                  <div
                    className={`${
                      tagDiscount
                        ? 'label-item-sku-date-container'
                        : 'label-item-sku-container'
                    }`}
                  >
                    {tagDiscount && labelsInformation[i].date_until && (
                      <div className="label-item-date-container">
                        <div
                          className="label-item-date-until"
                          style={{
                            fontSize: '10px',
                          }}
                        >
                          Vig.{' '}
                          {moment(
                            labelsInformation[i].date_until,
                            'YYYY-MM-DD HH:mm:ss'
                          ).format('DD/MM/YYYY')}
                        </div>
                      </div>
                    )}
                    <div className="label-item-sku">
                      {labelsInformation[i].SKU}
                    </div>
                  </div>
                  <div className="label-item-container textFont">
                    <div
                      className="label-item"
                      style={handlePrintLine(labelsInformation[i].Descripcion)}
                    >
                      {labelsInformation[i].Descripcion}
                    </div>
                  </div>
                  <div className="label-item-price-container">
                    <div
                      className={`${
                        tagDiscount
                          ? 'label-item-price-normal'
                          : 'label-item-price'
                      }`}
                    >
                      {formatterCurrency(labelsInformation[i].Precio)}
                    </div>
                  </div>
                  {tagDiscount && labelsInformation[i].discount_price && (
                    <div className="label-item-discount-price-container">
                      <div className="label-item-price-discount">
                        {formatterCurrency(labelsInformation[i].discount_price)}
                      </div>
                    </div>
                  )}
                  <div className="container-barcode-date">
                    <div className="barcode-image-container">
                      <img
                        className="label-item-barcode"
                        src={labelsInformation[i].inputRef}
                      />
                    </div>
                    <div className="label-item-date">
                      {moment().format('YYMMDD')}
                    </div>
                  </div>
                  {pages.length !== totalPages &&
                    handlePageBreaker(totalLabelsRendered)}
                </div>
              </>
            )

            if (totalLabelsRendered % pageBreakQuantity === 0) {
              // console.log('totalLabelsRendered SALTO', totalLabelsRendered)
              pages.push(
                <div className="page-print-labels" key={`page-${pages.length}`}>
                  {currentPage}
                </div>
              )
              currentPage = []
            }
          }
          totalLabelsCount++
        }

        if (currentPage.length > 0) {
          // console.log('currentPage', currentPage)
          pages.push(
            <div className="page-print-labels" key={`page-${pages.length}`}>
              {currentPage}
            </div>
          )
        }
        // setRenderingCounter(renderingCounter + 1)
        return pages
      } catch (e) {
        console.log(e)
        return toastSweetAlert(
          {
            mode: 'error',
            message: 'Error al imprimir etiquetas',
          },
          setPrintingLabels(false),
          setIsPrinting(false)
        )
      }
    }

    const handleLabelsFridge = () => {
      try {
        let totalLabelsCount = 0
        let totalLabelsRendered = 0
        let currentPage = []
        const pages = []
        // setIsPrinting(false)

        const totalQuantity = labelsInformation.reduce(
          (acc, label) => acc + parseInt(label.quantity),
          0
        )

        // console.log('totalQuantity', totalQuantity)

        for (let i = 0; i < labelsInformation.length; i++) {
          const labelQuantity = parseInt(labelsInformation[i].quantity)
          let totalPages = totalQuantity / 16
          totalPages = Math.ceil(totalPages)
          // console.log('totalLabelsCount', totalLabelsCount)

          // console.log('totalPages', totalPages)
          for (let j = 0; j < labelQuantity; j++) {
            totalLabelsRendered++
            currentPage.push(
              <div className="card-label-item" key={`${i}-${j}`}>
                <div className="logo-sku-date-container">
                  {/* <div className="logo-va-container">
                    <img className="logo-va" src={logoVA} />
                  </div> */}
                  <div className="label-item-sku">
                    {labelsInformation[i].SKU}
                  </div>
                  <div className="label-item-date">
                    {moment().format('YYMMDD')}
                  </div>
                </div>
                <div className="label-item-description-cotainer">
                  <div className="label-item-description">
                    {labelsInformation[i].Descripcion}
                  </div>
                </div>
                <div className="label-item-barcode-price-container">
                  <div className="barcode-image-container">
                    <img
                      className="label-item-barcode"
                      src={labelsInformation[i].inputRef}
                    />
                  </div>
                  <div className="label-item-price-container">
                    <div className="label-item-price">
                      {formatterCurrency(labelsInformation[i].Precio)}
                    </div>
                  </div>
                </div>
                {/* <div className="container-push-up"></div> */}
                {/* <div className="container-push-up"></div> */}
                {pages.length !== totalPages &&
                  handlePageBreakerFridge(totalLabelsRendered)}
              </div>
            )

            if (totalLabelsRendered % 16 === 0) {
              // console.log('totalLabelsRendered SALTO', totalLabelsRendered)
              pages.push(
                <div className="page-print-labels" key={`page-${pages.length}`}>
                  {currentPage}
                </div>
              )
              currentPage = []
            }
          }
          totalLabelsCount++
        }

        if (currentPage.length > 0) {
          // console.log('currentPage', currentPage)
          pages.push(
            <div className="page-print-labels" key={`page-${pages.length}`}>
              {currentPage}
            </div>
          )
        }
        // setRenderingCounter(renderingCounter + 1)
        return pages
      } catch (e) {
        console.log(e)
        return toastSweetAlert(
          {
            mode: 'error',
            message: 'Error al imprimir etiquetas',
          },
          setPrintingLabels(false),
          setIsPrinting(false)
        )
      }
    }

    return (
      <div
        id="containerLabels"
        ref={componentRef}
        // className="page-print-labels"
      >
        {labelsInformation.length > 0 && printingLabels && tagsForFridge
          ? handleLabelsFridge()
          : handleLabel()}
      </div>
    )
  }
)

export default PrintLabels
